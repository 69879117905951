<template>
    <div :class="calendarwrapcss">
        <div class="calendar_month" :class="monthcss">
            <h2 class="calendar_month_title">{{ monthtext }}</h2>
        </div>
        <div class="calendar_date_wrap">
            <div class="calendar_top">
                <div class="attendance">
                    <h2 class="attendance_title">나의 출석 현황</h2>
                    <div class="attendance_day">
                        <span class="attendance_check_day">{{ attendanceDate }}</span> 일
                    </div>
                </div>

                <div class="attendence_gift">
                    <div class="attendence_gift_wrap">
                        <div class="attendence_gift_circle">
                            <div class="attendence_gift_circle_succes" v-if="attendanceDate >= 3">
                                <figure class="applay_wrap">
                                    <img class="applay_img" src="/media/img/event/apply_success.png" alt="applay_success" />
                                </figure>
                                <p class="attendence_gift_circle_text">응모 완료</p>
                            </div>
                            <div class="evnet_prize evnet_prize3">
                                <figure class="mobile_oiling" :class="giftimg1css">
                                    <img class="img_100" :src="giftimg1url" alt="mobile_oiling" />
                                </figure>
                            </div>
                        </div>
                        <h1 class="attendence_title">
                            3일 출석 <br />
                            <!-- <span class="title_black">1만원 주유권</span> -->
                        </h1>
                    </div>
                    <div class="attendence_gift_wrap">
                        <div class="attendence_gift_circle">
                            <div class="attendence_gift_circle_succes" v-if="attendanceDate >= 15">
                                <figure class="applay_wrap">
                                    <img class="applay_img" src="/media/img/event/apply_success.png" alt="applay_success" />
                                </figure>
                                <p class="attendence_gift_circle_text">응모 완료</p>
                            </div>
                            <div class="evnet_prize evnet_prize2">
                                <figure class="airpods" :class="giftimg2css">
                                    <img class="img_100" :src="giftimg2url" alt="ginseng" />
                                </figure>
                            </div>
                        </div>
                        <h1 class="attendence_title">
                            15일 출석 <br />
                            <!-- <span class="title_black"> 에어팟 프로</span> -->
                        </h1>
                    </div>
                    <div class="attendence_gift_wrap">
                        <div class="attendence_gift_circle">
                            <div class="attendence_gift_circle_succes" v-if="attendanceDate >= 25">
                                <figure class="applay_wrap">
                                    <img class="applay_img" src="/media/img/event/apply_success.png" alt="applay_success" />
                                </figure>
                                <p class="attendence_gift_circle_text">응모 완료</p>
                            </div>
                            <div class="evnet_prize evnet_prize1">
                                <figure class="airpodsmax" :class="giftimg3css">
                                    <img class="img_100" :src="giftimg3url" alt="dyson_airwrap" />
                                </figure>
                            </div>
                        </div>
                        <h1 class="attendence_title">
                            25일 출석 <br />
                            <!-- <span class="title_black"> 에어팟 맥스</span> -->
                        </h1>
                    </div>
                </div>
            </div>
            <ul class="calendar_days">
                <li class="calendar_days_item sunday">Sun</li>
                <li class="calendar_days_item">Mon</li>
                <li class="calendar_days_item">Tue</li>
                <li class="calendar_days_item">Wed</li>
                <li class="calendar_days_item">Thu</li>
                <li class="calendar_days_item">Fri</li>
                <li class="calendar_days_item">Sat</li>
            </ul>
            <ul class="calendar_date">
                <li class="calendar_date_row" v-for="(weekItem, index) in month" :key="`month${index}`">
                    <ul class="calendar_week">
                        <li class="calendar_week_item" v-for="(dayItem, dayIndex) in weekItem" :key="`day${dayIndex}`">
                            <div class="week_box" v-if="dayItem['isMonth']">
                                <span class="week_date">{{ dayItem['day'] }}</span>
                                <figure class="circle_date" v-if="dayItem['check']">
                                    <img class="circle_date_img" src="/media/img/eventcalendar/check_date.png" alt="check_date" />
                                </figure>
                                <figure class="americano_wrap" v-if="dayItem['isSunday']">
                                    <img class="americano_img" src="/media/img/eventcalendar/americano.png" alt="americano" />
                                </figure>
                            </div>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import { getAttendenceInfo } from '@/service/api/menuApi';

export default {
  name: 'CalendarComp',
  props: {
    calendarwrapcss: {
      type: String,
      default: 'calendar_wrap',
      required: false
    },
    monthcss: {
      type: String,
      default: '',
      required: false
    },
    monthtext: {
      type: String,
      default: 'January 2024',
      required: false
    },
    giftimg1url: {
      type: String,
      default: `/media/img/eventcalendar/mobile_oiling.png`,
      required: false
    },
    giftimg2url: {
      type: String,
      default: `/media/img/eventcalendar/ginseng.png`,
      required: false
    },
    giftimg3url: {
      type: String,
      default: `/media/img/eventcalendar/dyson_airwrap.png`,
      required: false
    },
    giftimg1css: {
      type: String,
      default: '',
      required: false
    },
    giftimg2css: {
      type: String,
      default: '',
      required: false
    },
    giftimg3css: {
      type: String,
      default: '',
      required: false
    }
  },
  data () {
    return {
      nowMonth: new Date(),
      today: new Date(),
      month: [],
      attendanceDate: 0
    };
  },

  created () {
    this.buildCalendar();
    this.getAttendence();
  },

  methods: {
    getAttendence () {
      getAttendenceInfo(this.$cookies.get('userId')).then((res) => {
        const { result, resultCd } = res.data;
        if (resultCd === '0000') {
          const { days, attendanceDate } = result;
          this.attendanceDate = attendanceDate;
          this.month.forEach((monthItem) => {
            monthItem.forEach((weekItem) => {
              if (weekItem.isMonth) {
                const { id } = weekItem;
                const find = days.find((item) => Object.keys(item)[0] === id);
                const check = find[id];
                if (check === 'Y') {
                  weekItem.check = true;
                }
              }
            });
          });
        }
      });
    },

    buildCalendar () {
      const firstDate = new Date(this.nowMonth.getFullYear(), this.nowMonth.getMonth(), 1);
      const lastDate = new Date(this.nowMonth.getFullYear(), this.nowMonth.getMonth() + 1, 0);

      // 1월 확인용
      // const firstDate = new Date(dayjs().add(1, "month").startOf("month").format('YYYY-MM-DD'));
      // const lastDate = new Date(dayjs().add(1, "month").endOf("month").format('YYYY-MM-DD'));

      const totalWeek = Math.ceil((lastDate.getDate() + firstDate.getDay()) / 7);

      const firsDay = firstDate.getDay();
      let startDate = 1;
      let week = [];

      // 첫주
      for (let i = 0; i < 7; i++) {
        const day = {
          isMonth: false,
          id: `D_99`,
          check: false,
          isSunday: false,
          day: -1
        };

        if (i >= firsDay) {
          day.isMonth = true;
          day.id = `D_${startDate > 9 ? startDate : `0${startDate}`}`;
          day.day = startDate;
          startDate++;
        }
        if (i >= 7) {
          startDate++;
        }
        week.push(day);
      }

      this.month.push(week);
      week = [];

      // 둘째주 ~ 마지막주-1
      for (let weekIndex = 2; weekIndex < totalWeek; weekIndex++) {
        for (let i = 0; i < 7; i++) {
          const day = {
            isMonth: true,
            id: `D_99`,
            check: false,
            isSunday: false,
            day: startDate
          };
          day.id = `D_${startDate > 9 ? startDate : `0${startDate}`}`;
          startDate++;
          week.push(day);
          if (i >= 7) {
            startDate++;
          }
        }
        this.month.push(week);
        week = [];
      }

      // 마지막주
      for (let i = 0; i < 7; i++) {
        const day = {
          isMonth: false,
          id: `D_99`,
          check: false,
          isSunday: false,
          day: -1
        };
        if (startDate <= lastDate.getDate()) {
          day.id = `D_${startDate > 9 ? startDate : `0${startDate}`}`;
          day.isMonth = true;
          day.day = startDate;
          startDate++;
        }
        week.push(day);
      }
      this.month.push(week);
      week = [];
    }
  }
};
</script>
<style scoped src="@/assets/css/event/nextCalendarComponent.css"></style>
