import { render, staticRenderFns } from "./NextCalendarCompent.vue?vue&type=template&id=4b0bf680&scoped=true"
import script from "./NextCalendarCompent.vue?vue&type=script&lang=js"
export * from "./NextCalendarCompent.vue?vue&type=script&lang=js"
import style0 from "@/assets/css/event/nextCalendarComponent.css?vue&type=style&index=0&id=4b0bf680&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b0bf680",
  null
  
)

export default component.exports