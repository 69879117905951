<template>
    <div class="wrap" style="overflow: auto">
        <Aside v-if="asideStatus" @closeAside="asideStatus = false" />
        <div class="space_pc">
            <section class="main event">
                <div class="main_box" :class="fromMobile ? '' : 'disable'">
                    <h1 v-if="fromMobile">이벤트</h1>
                    <div class="event_content event_menu">
                        <ul>
                            <li>
                                <h3>{{ eventInfo.eventTitle }}</h3>
                                <span> {{ dateTime(eventInfo.eventStartDate) }} - {{ dateTime(eventInfo.eventEndDate) }}</span>
                            </li>
                            <li>
                                <p class="end_day pc_end_day" v-if="endDay(eventInfo.eventEndDate) >= 0">
                                    D-{{ endDay(eventInfo.eventEndDate) }}
                                </p>
                                <p class="end_day pc_end_day finished" v-else>종료</p>
                            </li>
                        </ul>
                        <div class="return_txt" v-if="!isAttendance">
                            <p v-if="eventInfo.eventImg">
                                <img :src="baseUrl + eventInfo.eventImg.substr(5)" alt="" />
                            </p>
                            <div class="marketing_wrap" v-if="isShowEventBtn">
                                <button class="marketing_btn" @click="goNaverEvent">체험단 신청하기</button>
                            </div>
                        </div>
                        <div class="return_txt" v-else>
                            <calendar-view v-if="eventInfo.eventId === 'event_000024'" />
                            <calendar-view-jan v-if="eventInfo.eventId === 'event_000025'" />
                            <event-feb v-if="eventInfo.eventId === 'event_000026'" />
                            <event-march v-if="eventInfo.eventId === 'event_000027'" />
                            <event-april v-if="eventInfo.eventId === 'event_000028'" />
                            <event-may v-if="eventInfo.eventId === 'event_000030'" />
                            <event-june v-if="eventInfo.eventId === 'event_000031'" />
                            <event-july v-if="eventInfo.eventId === 'event_000032'" />
                            <event-august v-if="eventInfo.eventId === 'event_000034'" />
                            <!-- KT 사전예약 이벤트 -->
                            <kt-iphone v-if="eventInfo.eventId === 'event_000035'" />
                            <kt-event v-if="eventInfo.eventId === 'event_000033'" />
                        </div>
                        <!-- 목록버튼 -->
                        <div class="hidden_btn" v-if="fromMobile">
                            <button @click="linkEventList()">목록</button>
                        </div>

                        <!-- 댓글영역 -->
                        <div class="main comment" v-if="eventInfo.eventCommentYn === 'Y'">
                            <h3>
                                댓글<span>{{ total }}</span>
                            </h3>
                            <div class="comment_wrap">
                                <div class="comment_input">
                                    <textarea
                                        class="text"
                                        maxlength="3000"
                                        rows="1"
                                        spellcheck="false"
                                        height="auto"
                                        placeholder="댓글을 입력해주세요."
                                        v-model="commentContents"
                                    ></textarea>
                                    <!-- <p>0<span>/200</span></p> -->
                                    <button @click="insertComment()"><img src="/media/img/event/btn_upload.svg" alt="" /></button>
                                </div>
                            </div>
                            <!-- 댓글유저명 -->
                            <template>
                                <div v-for="(item, index) in commentList" class="u_cbox_area" :key="index">
                                    <div class="u_cbox_name">
                                        <div class="u_cbox_info">
                                            <span class="u_cbox_nick">{{ item.email }}</span>
                                        </div>
                                        <div class="u_cbox_date">
                                            <span>{{ dateTime2(item.registDate) }}</span>
                                        </div>
                                    </div>
                                    <div class="u_cbox_text_wrap">
                                        <span class="u_cbox_contents" data-lang="ko">
                                            {{ item.commentContents }}
                                        </span>
                                    </div>
                                    <div class="recomment_wrap recomment_edit" style="display: none">
                                        <div class="recomment_input">
                                            <textarea
                                                class="text"
                                                maxlength="3000"
                                                rows="1"
                                                spellcheck="false"
                                                height="auto"
                                                placeholder="댓글을 입력해주세요."
                                                :value="item.commentContents"
                                            ></textarea>
                                            <div class="u_cbox_btn2">
                                                <button class="u_cbox_upload" @click="updateComment(item, $event)">등록</button>
                                                <button @click="changeCommentBox2($event)">취소</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="u_cbox_btn" v-if="item.userId === userId">
                                        <button @click="changeCommentBox($event)">수정</button>
                                        <button @click="deleteComment(item)">삭제</button>
                                    </div>
                                </div>
                            </template>
                            <pagination :pageSetting="pageDataSetting(total, limit, block, page)" @paging="pagingMethod" />
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <!-- 모바일 -->
        <div class="space_mo">
            <header class="header mo_header" v-if="fromMobile">
                <div class="header_box">
                    <div class="logo">
                        <a @click="clickPre()">
                            <img src="/media/images/icon_back_24.svg" alt="" />
                            <h2>이벤트</h2>
                        </a>
                    </div>
                    <div class="right_menu">
                        <div class="alarm_box" @click="mobileAlarm()">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M9.02759 19.7773C9.02759 20.5181 9.46002 21.9996 11.1897 21.9996C12.9195 21.9996 13.3519 20.5181 13.3519 19.7773"
                                    stroke="white"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M2.00024 18.666H20.3786"
                                    stroke="white"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M3.62183 18.1113V10.3335C3.62183 7.92613 5.13534 3.11133 11.1894 3.11133C17.2434 3.11133 18.7569 8.2965 18.7569 10.8891V18.1113"
                                    stroke="white"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <ellipse
                                    cx="17.6756"
                                    cy="6.44444"
                                    rx="4.32431"
                                    ry="4.44444"
                                    fill="#FF1150"
                                    v-if="$store.state.noticeCount !== 0"
                                />
                            </svg>
                        </div>
                        <div class="toggle">
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                @click="showAside()"
                            >
                                <g clip-path="url(#clip0_2231_20993)">
                                    <path
                                        d="M24 20L6 20L6 18L24 18L24 20ZM24 13L12 13L12 11L24 11L24 13ZM24 6L6 6L6 4L24 4L24 6Z"
                                        fill="white"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_2231_20993">
                                        <rect width="24" height="24" fill="white" transform="translate(24 24) rotate(180)" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                    </div>
                </div>
            </header>
            <section class="main event view" :class="fromMobile ? '' : 'disable'">
                <div class="main_box" :class="fromMobile ? '' : 'disable'">
                    <h1 v-if="fromMobile">이벤트</h1>
                    <div class="event_content event_menu">
                        <ul>
                            <li>
                                <p class="end_day pc_end_day" v-if="endDay(eventInfo.eventEndDate) >= 0">
                                    D-{{ endDay(eventInfo.eventEndDate) }}
                                </p>
                                <p class="end_day pc_end_day finished" v-else>종료</p>
                                <h3>{{ eventInfo.eventTitle }}</h3>
                                <span> {{ dateTime(eventInfo.eventStartDate) }} - {{ dateTime(eventInfo.eventEndDate) }}</span>
                            </li>
                        </ul>
                        <div class="return_txt" v-if="!isAttendance">
                            <p v-if="eventInfo.eventImg">
                                <img :src="baseUrl + eventInfo.eventImg.substr(5)" width="100%;" alt="" />
                            </p>
                            <div class="marketing_wrap" v-if="isShowEventBtn">
                                <button class="marketing_btn" @click="goNaverEvent">체험단 신청하기</button>
                            </div>
                        </div>
                        <div class="return_txt" v-else>
                            <calendar-view v-if="eventInfo.eventId === 'event_000024'" />
                            <calendar-view-jan v-if="eventInfo.eventId === 'event_000025'" />
                            <event-feb v-if="eventInfo.eventId === 'event_000026'" />
                            <event-march v-if="eventInfo.eventId === 'event_000027'" />
                            <event-april v-if="eventInfo.eventId === 'event_000028'" />
                            <event-may v-if="eventInfo.eventId === 'event_000030'" />
                            <event-june v-if="eventInfo.eventId === 'event_000031'" />
                            <event-july v-if="eventInfo.eventId === 'event_000032'" />
                            <event-august v-if="eventInfo.eventId === 'event_000034'" />
                            <!-- KT 사전예약 이벤트 -->
                            <kt-iphone v-if="eventInfo.eventId === 'event_000035'" />
                            <kt-event v-if="eventInfo.eventId === 'event_000033'" />
                        </div>
                        <!-- 목록버튼 -->
                        <div class="hidden_btn" v-if="fromMobile">
                            <a>
                                <button @click="linkEventList()">목록</button>
                            </a>
                        </div>

                        <!-- 댓글영역 -->
                        <div class="main comment" v-if="eventInfo.eventCommentYn === 'Y'">
                            <h3>
                                댓글<span>{{ total }}</span>
                            </h3>
                            <div class="comment_wrap">
                                <div class="comment_input">
                                    <textarea
                                        class="text"
                                        maxlength="3000"
                                        rows="1"
                                        spellcheck="false"
                                        height="auto"
                                        placeholder="댓글을 입력해주세요."
                                        v-model="commentContents"
                                    ></textarea>
                                    <!-- <p>0<span>/200</span></p> -->
                                    <button @click="insertComment()"><img src="/media/img/event/btn_upload.svg" alt="" /></button>
                                </div>
                            </div>
                            <!-- 댓글유저명 -->
                            <template>
                                <div class="u_cbox_area" v-for="(item, index) in commentList" :key="index">
                                    <div class="u_cbox_name">
                                        <div class="u_cbox_info">
                                            <span class="u_cbox_nick">{{ item.email }}</span>
                                        </div>
                                        <div class="u_cbox_date">
                                            <span>{{ dateTime2(item.registDate) }}</span>
                                        </div>
                                    </div>
                                    <div class="u_cbox_text_wrap">
                                        <span class="u_cbox_contents" data-lang="ko">
                                            {{ item.commentContents }}
                                        </span>
                                    </div>
                                    <div class="recomment_wrap recomment_edit" style="display: none">
                                        <div class="recomment_input">
                                            <textarea
                                                class="text"
                                                maxlength="3000"
                                                rows="1"
                                                spellcheck="false"
                                                height="auto"
                                                placeholder="댓글을 입력해주세요."
                                                :value="item.commentContents"
                                            ></textarea>
                                            <div class="u_cbox_btn2">
                                                <button class="u_cbox_upload" @click="updateComment(item, $event)">등록</button>
                                                <button @click="changeCommentBox2($event)">취소</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="u_cbox_btn" v-if="item.userId === userId">
                                        <button @click="changeCommentBox($event)">수정</button>
                                        <button @click="deleteComment(item)">삭제</button>
                                    </div>
                                </div>
                            </template>
                            <paginationMo :pageSetting="pageDataSetting(total, limit, block, page)" @paging="pagingMethod" />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import pagination from '@/components/pagination/pagination.vue';
import paginationMo from '@/components/pagination/paginationMo.vue';
import Aside from '@/layout/aside/Aside.vue';
import axios from 'axios';
import VueCookies from 'vue-cookies';
import $ from 'jquery';
import dayjs from 'dayjs';
import CalendarView from '@/views/aplayzevent/CalendarView.vue';
import CalendarViewJan from '@/views/aplayzevent/NextCalendarView.vue';
export default {
  name: 'eventView',
  data () {
    return {
      asideStatus: false,
      eventInfo: {},
      commentList: [],
      commentContents: '',
      baseUrl: `${process.env.VUE_APP_BASE_URL}/media`,
      total: 0,
      page: 1,
      limit: 10,
      block: 10,
      userId: VueCookies.get('userId')
    };
  },
  components: {
    pagination,
    paginationMo,
    Aside,
    'calendar-view': CalendarView,
    'calendar-view-jan': CalendarViewJan,
    'event-feb': () => import(`@/components/attendence/february/FebruaryEvent.vue`),
    'event-march': () => import(`@/components/attendence/march/MarchEvent.vue`),
    'event-april': () => import(`@/components/attendence/april/AprilEvent.vue`),
    'event-may': () => import(`@/components/attendence/may/MayEvent.vue`),
    'event-june': () => import(`@/components/attendence/june/JuneEvent.vue`),
    'event-july': () => import(`@/components/attendence/july/JulyEvent.vue`),
    'kt-event': () => import(`@/components/collaboration/KtEvent.vue`),
    'event-august': () => import(`@/components/attendence/august/AugustEvent.vue`),
    'kt-iphone': () => import(`@/components/eventView/ktiphone/KtIphone.vue`)
  },
  async created () {
    await this.getEventInfo();
    await this.getEventComment();
  },
  methods: {
    goNaverEvent () {
      window.open('https://naver.me/x4uaFTFU', '_blank');
    },
    mobileAlarm () {
      this.$router.push({ name: 'Notice0' });
    },
    clickPre () {
      history.back();
    },
    showAside () {
      this.asideStatus = true;
    },
    linkEventList () {
      this.$router.push({ name: 'Event' });
    },
    changeCommentBox (e) {
      var select = e.currentTarget.parentElement;
      select.style.display = 'none';
      $(select.parentElement).find('.u_cbox_text_wrap')[0].style.display = 'none';
      $(select.parentElement).find('.recomment_wrap')[0].style.display = '';
    },
    changeCommentBox2 (e) {
      var select = e.currentTarget.parentElement.parentElement.parentElement;
      $(select.parentElement).find('.u_cbox_text_wrap')[0].style.display = '';
      $(select.parentElement).find('.u_cbox_btn')[0].style.display = '';
      select.style.display = 'none';
    },
    endDay (value) {
      var today = dayjs();
      var expireDate = dayjs(value);
      var endDay = expireDate.diff(today, 'day', true);
      return Math.floor(endDay);
    },
    dateTime (value) {
      return dayjs(value).format('YY.MM.DD');
    },
    dateTime2 (value) {
      return dayjs(value).format('YY.MM.DD HH:mm');
    },
    deleteComment (item) {
      var commentId = item.commentId;
      var eventId = this.$store.state.eventId;
      var userId = VueCookies.get('userId');
      var aToken = VueCookies.get('aToken');
      var headers = { 'X-AUTH-TOKEN': aToken };

      axios
        .post('/api/menu/deleteEventComment', { eventId, userId, commentId }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            this.getEventComment();
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    updateComment (item, e) {
      this.changeCommentBox2(e);
      const commentContents = $(e.currentTarget.parentElement.parentElement).find('textarea')[0].value;
      const eventId = this.$store.state.eventId;
      const commentId = item.commentId;
      const userId = VueCookies.get('userId');
      const aToken = VueCookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };

      axios
        .post('/api/menu/updateEventComment', { userId, eventId, commentId, commentContents }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            this.getEventComment();
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    insertComment () {
      const userId = VueCookies.get('userId');
      const aToken = VueCookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };
      const eventId = this.$store.state.eventId;
      const commentContents = this.commentContents;

      axios
        .post('/api/menu/insertEventComment', { userId, eventId, commentContents }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            this.commentContents = '';
            this.getEventComment();
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    async getEventInfo () {
      const aToken = VueCookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };
      const eventId = this.$store.state.eventId;
      await axios
        .post('/api/menu/getEvent', { eventId }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            this.eventInfo = res.data.result;
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    async getEventComment (page) {
      const aToken = VueCookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };
      const eventId = this.$store.state.eventId;
      const limit = this.limit;
      const offset = (page - 1) * this.limit;
      await axios
        .post('/api/menu/getEventComment', { eventId, limit, offset }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            var result = res.data.result;
            this.commentList = result.eventCommentList;
            this.total = result.total;
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    pagingMethod (page) {
      this.getEventComment(page);
      this.page = page;
      this.pageDataSetting(this.total, this.limit, this.block, this.page);
    },
    pageDataSetting (total, limit, block, page) {
      const totalPage = Math.ceil(total / limit);
      const currentPage = page;
      const first = currentPage > 1 ? parseInt(currentPage, 10) - parseInt('1', 10) : null;
      const end = totalPage !== currentPage ? parseInt(currentPage, 10) + parseInt('1', 10) : null;

      const startIndex = (Math.ceil(currentPage / block) - 1) * block + 1;
      const endIndex = startIndex + block > totalPage ? totalPage : startIndex + block - 1;
      const list = [];
      for (let index = startIndex; index <= endIndex; index++) {
        list.push(index);
      }
      return { first, end, list, currentPage };
    }
  },
  computed: {
    isShowEventBtn () {
      const showList = ['event_000107', 'event_000022'];
      return !!showList.includes(this.eventInfo.eventId);
    },
    isAttendance () {
      return (
        this.eventInfo.eventId === 'event_000024' ||
        this.eventInfo.eventId === 'event_000025' ||
        this.eventInfo.eventId === 'event_000026' ||
        this.eventInfo.eventId === 'event_000027' ||
        this.eventInfo.eventId === 'event_000028' ||
        this.eventInfo.eventId === 'event_000030' ||
        this.eventInfo.eventId === 'event_000031' ||
        this.eventInfo.eventId === 'event_000032' ||
        this.eventInfo.eventId === 'event_000033' ||
        this.eventInfo.eventId === 'event_000034' ||
        this.eventInfo.eventId === 'event_000035'
      );
    },
    fromMobile () {
      return this.$store.getters.gettersFromMobile;
    }
  }
};
</script>

<style scoped>
.space_mo .main_box {
  margin: 0 auto;
}

.end_day.pc_end_day {
  color: #ff2d55 !important;
}

.end_day.pc_end_day.finished {
  background: #888888;
  color: #fff;
}

@media all and (max-width: 800px) {
  .event .event_menu ul {
    padding: 0 5%;
    margin-bottom: 24px;
  }
}
</style>
<style src="@/assets/css/content2.css"></style>
<style src="@/assets/css/font.css"></style>
